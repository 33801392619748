import BasePlugin from '../BasePlugin'

export default class AddRegisteredMembers extends BasePlugin {
  async execute () {
    let member = {
      'memberId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddRegisteredMembersCommand`,
      member,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
